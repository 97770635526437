.ranking__wrapper{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
    background-color: #fff;

    width: 100%;
    height: 100vh;

    display: none;
    flex-direction: column;
    justify-content: space-between;

    padding: res(25) 0;

    &.active{
        display: flex;
    }

    .btn__back{
        display: flex;
        align-items: center;
        gap: res(4);

        padding-bottom: res(25);
        margin-left: res(12);

        img{
            transform: rotate(180deg);
        }

        p{
            color: $primary-color;
            font-family: $medium;
            letter-spacing: res(-0.7);
        }
    }

    .header{
        text-align: center;

        .title{
            letter-spacing: res(0.37);
            font-size: res(20);
            font-family: $bold;
            color: black;
            padding-bottom: res(12);
        }

        span{
            letter-spacing: res(-0.24);
            font-size: res(14);
            font-family: $regular;
            color: black;
        }
    }

    .profile__info{
        margin-top: res(32);
        padding: res(12) res(20);

        .profile__info--wrapper{
            display: flex;
            align-items: center;
            gap: res(12);
        }

        justify-content: space-between;
    }

    .score{
        display: flex;
        align-items: center;
        gap: res(4);

        img{
            width: res(28);
            height: res(28);
        }

        p{
            letter-spacing: res(-0.24);
            font-size: res(14);
            font-family: $regular;
            color: $primary-color;
        }
    }
}

.ranking{
    padding: 0 res(12);
    width: 100%;
    margin-top: res(24);

    .ranking__head{
        padding-bottom: res(12);
        display: flex;
        align-items: center;
        justify-content: space-between;

        p{
            font-size: res(14);
            font-family: $medium;
            letter-spacing: res(-0.24);
            color: black;
            opacity: .40;
        }
    }
}

.ranking__list{
    display: block;
    overflow: auto;
    width: 100%;
    height: res(400);
    padding: 0 res(5);
    gap: res(12);
    margin-bottom: res(10);
    padding-bottom: 35%;

    .ranking__list__item{
        display: grid;
        grid-template-columns: auto auto 1fr;
        align-items: center;
        /* justify-content: space-between; */
        height: res(64);

        .top{
            width: res(26);
            height: res(26);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: res(16);
            font-family: $bold;
            letter-spacing: res(-0.24);
            color: $primary-color;

            img{
                width: res(26);
                height: res(26);
            }
        }

        .user__data{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: res(12);
            flex-direction: row-reverse;
            width: fit-content;
            p{
                font-size: res(14);
                font-family: $bold;
                letter-spacing: res(-0.24);
                color: $primary-color;
            }

            img{
                width: res(32);
                height: res(32);
            }
        }

        .score{
            width: 100%;
            justify-content: flex-end;
        }
    }
}