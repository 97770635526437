.page[page="bonus"]{
    flex-direction: column;
    gap: res(25);
}

.bonus__header{
    color: $primary-color;
    text-align: center;

    p{
        font-size: res(20);
        font-family: $bold;
        padding-bottom: res(12);
    }

    span{
        font-size: res(14);
        font-family: $regular;
    }
}

.bonus__image{
    width: res(205);
    height: res(210);

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.bonus__btn{
    width: 100%;

    .btn{
        width: 100%;
        margin-top: res(12);
    }

    .bonus__time{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $primary-color;
        width: 100%;

        p{
            font-size: res(14);
            font-family: $regular;
        }

        span{
            font-size: res(20);
            font-family: $bold;
        }

    }
}

@media screen and (max-height: 645px){
    .bonus__image{
        width: res(135)!important;
        height: res(122)!important;
    }
}