.header{
    width: 100%;
    padding: res(12);
    padding-bottom: res(20);
    border-bottom-left-radius: res(35);
    border-bottom-right-radius: res(35);
    box-shadow: res(0) res(2) res(4) res(0) rgba(0, 0, 0, 0.15);
}

.profile__header{
    display: flex;
    flex-direction: column;
    row-gap: res(15);
    position: relative;

    span{
        color: $primary-color;
        font-size: res(12);
        font-family: $regular;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: res(12);
    }
}

.profile__info{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left{
        display: flex;
        align-items: center;
        gap: res(13);

        .ranking__btn{
            cursor: pointer;

            img{
                width: res(28);
                height: res(28);
            }
        }

        .avatar{
            width: res(44);
            height: res(44);
            border-radius: 50%;
            background-color: black;
            border: res(2) solid #414141;
            padding: res(2);
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: res(42);
                height: res(42);
                border-radius: 100%;
                object-fit: 100%;
            }
        }

        .nickname{
            font-size: res(14);
            color: $primary-color;
            font-family: $bold;
        }
    }

    .wallet__link{
        display: flex;
        align-items: center;
        gap: res(10);
        cursor: pointer;
        margin-right: res(20);
        position: relative;

        .icon{
            width: res(20);
            height: res(17);
        }

        p.wallet__link--text{
            font-size: res(14);
            font-family: $bold;
            color: #414141;
            letter-spacing: -0.24%;
        }

        
        .wallet__popup {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: white;
            border: res(1) solid #ccc;
            padding: res(10);
            border-radius: res(4);
            box-shadow: 0 res(4) res(8) rgba(0, 0, 0, 0.1);
            flex-direction: column;
        }
        
        .wallet__popup--button {
            padding: res(5) res(10);
            cursor: pointer;
        }
    }
}

.profile__data{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .profile__data--item{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: res(13);
        user-select: none;
        cursor: pointer;
        width: res(115);
        height: res(38);
        box-shadow: 0 0 res(4) rgba(0, 0, 0, 0.15);
        border-radius: res(20);

        img{
            width: res(24);
            height: res(24);
            object-fit: 100%;
        }

        p{
            font-size: res(16);
            color: $primary-color;
            font-family: $bold;
        }
    }
}

.referal__header{
    height: res(97);
    display: flex;
    align-items: center;
    gap: res(12);

    .current__stats{
        width: res(196);
    }

    .general__stats{
        width: res(158);
    }

    .general__stats, .current__stats{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: res(12);
        color: $primary-color;

        p{
            font-size: res(20);
            font-family: $bold;
        }

        span{
            font-size: res(12);
            font-family: $regular;
            padding-top: res(5);
        }

        .btn{
            height: res(32);
            border-radius: res(10);
            box-shadow: none;
            font-size: res(12);
            width: 100%;
            color: #fff;
            margin-top: res(12);
        }

        .current__stats__info{
            display: flex;
            align-items: center;
            gap: res(12);

            img{
                width: res(46);
                height: res(46);
            }
        }
    }
}