.page[page="main"]{
    flex-direction: column;

    .score{
        display: flex;
        align-items: center;

        p{
            font-size: res(32);
            font-family: $bold;
            letter-spacing: res(0.37);
            color: $primary-color;
        }

        img{
            width: res(36);
            height: res(36);
            object-fit: cover;
            margin-left: res(8);
        }
    }
}

.play{
    width: res(325);
    height: res(325);
    /* border-radius: 100%;
    box-shadow: 0 0 res(40) rgba(255, 0, 0, 0.6), 0 res(4) res(20) rgba(0, 0, 0, 0.3), inset 0 res(-4) res(10) rgba(0, 0, 0, 0.6); */
    cursor: pointer;
    position: relative;
    /* margin-top: res(40); */

    /* &::after{
        content: '';
        position: absolute;
        width: res(201);
        height: res(201);
        border: res(2) solid #DB0404;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        border-radius: 100%;
    } */

    img{
        width: res(325);
        height: res(325);
    }
}