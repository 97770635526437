@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
$montserrat-alt: "Montserrat Alternates", sans-serif;


.start{
    width: 100%;
    height: 100%;
    padding: 0 res(12);
    position: absolute;
    z-index: 99999999;
    top: 0;
    left: 0;
    background-color: #fff;
    
    .start__body{
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        height: 100%;
        gap: res(4);
    }
}

.start__top{
    display: flex;
    justify-content: space-between;
    height: res(36);
    align-items: center;
    
    .start__steps{
        width: res(220);
        display: flex;
        justify-content: space-between;
        
        .start__steps--item{
            width: res(36);
            height: res(4);
            background-color: #F0F0F0;
            border-radius: res(12);
            
            &.passed{
                background-color: #FF0000;
            }
        }
    }
    
    .start__skip{
        font-size: res(14);
        font-family: $montserrat-alt;
        font-weight: 500;
        color: red;
    }
}

.start__nav {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    padding-bottom: res(15);
    
    .start__nav--controll{
        width: 100%;
        height: res(52);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: res(16)!important;
        letter-spacing: res(-0.24)!important;
        font-weight: bold!important;
        font-family: $montserrat-alt!important;
        
        .controll{
            display: flex;
            align-items: center;
            gap: res(4);
            
            img{
                width: res(28);
                height: res(28);
            }
        }
        
        .start__prev{
            color: #414141;
        }
        
        .start__next{
            color: red;
        }
        
        .btn{
            height: 100%;
            box-shadow: none;
            font-weight: bold;
        }
    }
}

.start__content{
    display: flex;
    flex-direction: column;
    
    .start__title{
        text-align: center;
        padding-bottom: res(15);
        font-size: res(24);
        font-family: $montserrat-alt;
        letter-spacing: res(-0.24);
        color: #414141;
        font-weight: bold;
        margin-top: res(20);
    }
    
    .arrow__down{
        margin: 0 auto;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .step__image{
        /* width: 100%;
        max-height: res(290);
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        } */
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: res(20);
        pointer-events: none;
        max-height: res(227);
        padding-bottom: res(20);

        .shop__lists{
            transform: scale(0.8);
        }
        .tasks__list{
            transform: scale(0.8);
        }
    }
    
    .step__list{
        padding: res(12);
        display: flex;
        flex-direction: column;
        gap: res(8);
        /* margin-top: 10%; */
        
        .step__list--item{
            font-size: res(15);
            font-weight: 500;
            color: #414141;
            font-family: $montserrat-alt;
            position: relative;
            padding-left: res(30);
            
            &:after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background: #414141;
                width: res(4);
                height: res(4);
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                margin-left: res(15);
            }
            
            span.black{
                font-weight: bold;
            }
            
            span.red{
                color: red;
            }
        }
    }
}