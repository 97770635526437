// reset & initial settings

*, 
*::after, 
*::before{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    /* user-select: none!important; */
    -webkit-tap-highlight-color: transparent !important;
}

a{
    text-decoration: none;
}

ul,
ol,
li{
    list-style: none;
}

img{
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-weight: inherit;
    font-size: inherit;
}

html,
body{
    height: 100%;
    line-height: 1;
}

img{
    user-select: none;
}

/* [class*="__container"]{
    
} */

.wrapper{
    display: flex;
    flex-direction: column;
    height: var(--content-height);
    overflow: hidden;
    width: 100%;
    max-width: res(425);
    margin: 0 auto;
    position: relative;
    background-color: #fefefe;
}

main{
    flex: 1 1 auto;
    padding-bottom: res(60);
    overflow: hidden;
}

.page{
    display: none;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - res(129) - res(60));
    height: 100%;
    /* height: 100%;
    min-height: res(474); */
    padding-left: res(12);
    padding-right: res(12);
    padding-top: res(20);
    padding-bottom: res(20);
}

.page.active{
    display: flex;
}

// vars

$primary-color: #414141;
$primary-grey: #999999;

// btn

.btn{
    cursor: pointer;
}

.primary--btn{
    padding: res(16) res(20);
    background: #FF0000;
    box-shadow: 0 0 res(8) #FF0000;
    border-radius: res(20);
    color: white;
    font-size: res(20);
    font-family: $bold;
    height: res(56);
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled{
        background-color: $primary-grey;
        box-shadow: none;
    }
}

.telegram-finance{
    width: 100%!important;
    margin-top: res(10)!important;
    font-size: res(18)!important;
    font-family: $medium!important;
    box-shadow: none!important;

    svg{
        margin-right: res(10);
    }
}

p.both{
    text-align: center;
    color: black;
    font-size: res(16);
    font-family: $regular;
    letter-spacing: 0.37%;
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FF0000;
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        width: 100%;
        height: 100%;
    }
}

.loading__border {
    width: 70%;
    height: 10px;
    background-color: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.35);
}

.loading-bar {
    width: 0;
    height: 100%;
    background-color: #FF0000;
    transition: width 0.2s ease;
}

@media (max-height: 645px){

}

/* ton */

.tc-modal, .go823957079{
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 9999999999 !important;
}

.tc-modal-content {
    max-width: 90%;
    max-height: 90%;
    padding: res(20);
    overflow-y: auto;
    border-radius: res(8);
}

.go1392445990{
    padding: 0!important;
}

.go1959085787{
    padding: res(10)!important;
}

.go3516602140{
    padding: res(10)!important;
}

.go823957079{
    padding: 0!important;
}

.go3872688706, .go1901766449{
    width: 100%!important;
    border-radius: 0!important;
}

.go2218940523{
    height: res(57)!important;
    padding: res(16)!important;
}

.go3646674824, .go653478106{
    margin-top: 0!important;
    padding-top: res(60)!important;
}

/* .go4160105817{
    padding-top: res(20)!important;
} */

.wallet__btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: res(27);
    gap: res(12);

    .btn{
        box-shadow: none!important;
        margin-top: 0!important;
    }
    
    .btn:first-child{
        width: 100%;
    }

    .btn:last-child{
        width: res(60)!important;
    }
}

// ====

#language-switch{
    position: absolute;
    bottom: res(80);
    right: res(13);
    width: res(50);
    height: res(50);
    background-color: red;
    border-radius: 50%;
    cursor: pointer;
    font-size: res(14);
    color: white;
    font-family: $semibold;
    text-transform: uppercase;
    box-shadow: 0 0 res(8) #FF0000;
}