.page[page="referal"]{
    flex-direction: column;
    p.head{
        font-size: res(20);
        font-family: $bold;
        color: $primary-color;
    }
}

.referal__level__lists{
    display: flex;
    flex-direction: column;
    gap: res(12);
    margin-top: res(12);
    width: 100%;
}

.referal__level__item{
    width: 100%;
    height: res(50);
    display: flex;
    align-items: center;
    padding-left: res(16);
    padding-right: res(16);
    justify-content: space-between;
    box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
    border-radius: res(12);

    &:hover{
        background-color: rgba(0, 0, 0, 0.05);
    }

    p{
        font-size: res(16);
        font-family: $bold;
        color: $primary-color;
    }

    .friends{
        display: flex;
        align-items: center;
        gap: res(8);
    }
}

.referal__btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: res(27);
    gap: res(12);
    
    .btn:first-child{
        width: 100%;
    }
}

.modal__content .users__list{
    display: flex;
    flex-direction: column;
    gap: res(8);
    margin-top: res(20);
    width: 100%;

    .user{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: res(44);
        width: 100%;
        box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
        border-radius: res(20);
        padding: 0 res(20);

        .name{
            letter-spacing: res(-0.24);
            font-size: res(16);
            font-family: $bold;
            color: $primary-color;
        }

        .date{
            letter-spacing: res(-0.24);
            font-size: res(16);
            font-family: $medium;
            color: $primary-color;
        }
    }
}

.pagination{
    margin-top: res(20);
    gap: res(22);

    div{
        gap: res(10);
    }

    button{
        background-color: transparent;
    }

    /* .prev{
        margin-top: ;
    } */
}

.modal__content {
    display: flex;
    flex-direction: column;
}

.users__list {
    flex-grow: 1;
    overflow-y: auto;
    margin-top: res(20);
    display: flex;
    flex-direction: column;
    gap: res(8);
    padding: res(2);
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: res(20);
    gap: res(22);
}

.pagination button {
    background-color: transparent;
}

@media screen and (max-height: 645px){
    .referal__level__item{
        height: res(43)!important;
    
        p{
            font-size: res(14)!important;
        }
    
    }

    .referal__btn{
        margin-top: res(10)!important;
    }
}