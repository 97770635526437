.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 999999999;
    padding: 0 res(12);
}

.modal--active {
    display: flex;
}

.modal--expanded {
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
}

.modal.shop{
    padding: 0;

    .modal__content{
        align-self: flex-end;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        min-height: res(300);
        padding-top: res(29);

        #connect-ton-btn{
            box-shadow: none!important;
        }

        .wallet-dropdown{
            background-color: #fff;
            height: res(39);
            font-size: res(14);
            font-family: $medium;
            color: $primary-color;
            letter-spacing: res(0.37);
            width: 100%;
            outline: none;
            box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
            border: none;
            border-radius: res(12);
            padding-left: res(12);
            padding-right: res(12);
        }
    }
}

.modal__head{
    color: black;
    font-size: res(20);
    letter-spacing: res(0.37);
    font-family: $bold;
}

.modal__span{
    color: black;
    font-size: res(14);
    letter-spacing: -0.24%;
    font-family: $medium;
    margin-top: res(8);
}

.shop__content{
    width: 100%;
    padding: 0 res(18);
    margin-top: res(25);
}

.channel__boost{
    display: block;
    width: 100%;
    overflow: auto;
    max-height: res(400);


    .channel__boost__item{
        height: res(64);
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
        border-radius: res(12);
        padding: 0 res(16);
        margin-top: res(20);

        .left{
            display: flex;
            align-items: center;
            /* gap: res(12); */

            img{
                width: res(38);
                height: res(38);
            }

            p{
                font-size: res(16)!important;
                font-family: $bold;
                color: $primary-color;
                letter-spacing: -0.24%;
            }
        }

        .btn{
            width: res(105);
            height: res(38);
            margin-top: 0;
            gap: res(3);
            box-shadow: 0!important;
            border-radius: res(12);
            font-size: res(16)!important;
            padding-left: res(10)!important;
            padding-right: res(10)!important;

            img{
                width: res(20);
                height: res(20);
            }
        }
    }
}

.modal__content {
    background-color: white;
    padding: res(13);
    padding-top: res(40);
    border-radius: res(32);
    position: relative;
    width: 100%;
    min-height: res(100);
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: max-height 0.3s ease;

    p.title,
    .modal__title{
        font-size: res(24);
        color: $primary-color;
        letter-spacing: res(0.37);
        font-family: $bold;
        margin-top: res(25);
        padding-bottom: res(12);
    }

    span.text,
    .modal__body{
        font-size: res(18);
        letter-spacing: -0.24%;
        color: $primary-color;
        font-family: $regular;
        line-height: res(30);
        text-align: center;

        &.mtube{
            font-family: $bold;
            color: red;
            font-size: res(24);
            text-align: center;
        }
    }

    .modal__image{
        img{
            width: res(125);
            height: res(125);
        }
    }

    .btn{
        width: 100%;
        margin-top: res(40);
    }
}

span.modal__close {
    position: absolute;
    top: res(29);
    right: res(29);
    cursor: pointer;

    img{
        width: res(21);
        height: res(26);
    }
}

.no-scroll {
    overflow: hidden;
}

.exchange__list{
    display: flex;
    flex-direction: column;
    gap: res(12);

    .exchange__list__item{
        width: 100%;
        height: res(84);
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background-color: #fff;
        box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
        border-radius: res(20);
        padding: 0 res(12);

        .exchange{
            display: flex;
            align-items: center;
            gap: res(8);
        }

        p.title{
            color: black;
            font-size: res(18);
            letter-spacing: res(0.37);
            font-family: $bold;
            margin-top: res(15.87)!important;
        }

        img{
            width: res(44);
            height: res(44);
        }

        span img{
            width: res(16);
            height: res(16);
        }

        .from{
            width: res(48);
            height: res(48);
        }
    }
}

.back-button{
    position: absolute;
    top: res(29);
    left: res(29);
    background-color: transparent;
    svg{
        width: res(26);
        height: res(26);
        transform: rotate(180deg);

        path{
            fill: #999999;
        }
    }
}

.form-title{
    display: none;
}

.exchange__form{
    height: res(480);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wallet-select {
    width: 100%;
    height: res(52);
    border-radius: res(12);
    padding: 0 res(15);
    margin-bottom: res(10);
    box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
    font-size: res(14);
    color: #303030;
    font-family: $medium;
    border: 0;
    outline: 0;

    option{
        *[selected]{
            font-family: $bold!important;
        }
    }
    
}

input[disabled] {
    background-color: #f5f5f5;
    cursor: not-allowed;
}


.input__wrapp{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn{
        margin-top: 0;
    }

    .input__item{
        display: flex;
        flex-direction: column;
        padding: res(12);
        padding-bottom: res(20);
        gap: res(12);

        p.exchange-rate{
            font-size: res(14);
            font-family: $regular;
            letter-spacing: res(0.37);
            color: #999999;
        }

        &:first-child{
            border-bottom: res(1) solid #9999996b;
        }

        img{
            width: res(38);
            height: res(38);
        }

        .data{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: res(39);
            gap: res(12);

            .currency{
                font-size: res(28);
                font-family: $bold;
                color: $primary-grey;
                letter-spacing: res(0.37);
            }

            input{
                outline: none;
                border: 0;
                height: 100%;
                width: 100%;

                font-size: res(32);
                font-family: $bold;
                color: $primary-color;
                letter-spacing: res(0.37);
            }
        }

        .head{
            display: flex;
            align-items: center;
            gap: res(12);
            font-size: res(16);
            font-family: $regular;
            color: $primary-grey;
            letter-spacing: -0.24%;
            img{
                width: res(48);
                height: res(48);
            }
        }
    }
}

.withdrawal__form, .wallet__form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: res(450);

    .head__wrapp{
        font-size: res(14)!important;
            font-family: $regular;
            color: $primary-grey;
            letter-spacing: -0.24%;

        .head{
            font-size: res(14)!important;

            img{
                width: res(35);
                height: res(35);
            }
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .input__item{
        &:first-child{
            border-bottom: none;
        }
        gap: res(20);

        p.balance{
            font-size: res(14)!important;
            font-family: $regular;
            color: $primary-grey;
            letter-spacing: -0.24%;
        }
    }

    .adress{
        .data{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .label{
                font-size: res(16)!important;
                font-family: $regular;
                color: #303030;
                letter-spacing: -0.24%;

                span{
                    font-family: $bold;
                }
            }

            input{
                font-size: res(14);
                letter-spacing: -0.24%;
                font-family: $medium;
                color: black;
                height: res(52);
                border-radius: res(12);
                padding: 0 res(15);
                padding-right: res(30);

                box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);

                &::placeholder{
                    color: rgba(60, 60, 67, 0.3);
                }
            }
        }
    }
}

.wallet__form{
    height: auto;
    margin-top: res(20);
    input{
        width: 100%;
        margin-top: res(10);
    }
}

.exchange__form--shifted {
    /* transform: translateY(res(-263)); */
    position: fixed;
    top: 2%;
    /* left: 50%;
    transform: translateX(-50%); */
    transition: 0.3s ease;

    .exchange__form{
        justify-content: flex-start;
    }

    .withdrawal__form{
        justify-content: flex-start;

        .btn{
            margin-top: res(65);
        }
    }

    .exchange__form--shifted{
        justify-content: flex-start;
    }
}
.input-wrapper {
    position: relative;
    width: 100%;
}

.wallet-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: res(1) solid #ccc;
    border-radius: res(4);
    height: res(40)!important;
    max-height: res(100)!important;
    overflow-y: auto;
    display: none;
    z-index: 100;
    margin-top: res(8);
}

.wallet-dropdown li {
    padding: res(10);
    cursor: pointer;
    font-size: res(14);
    font-family: $medium;
}

.wallet-dropdown li:hover {
    background-color: #f0f0f0;
}

.wallet-combo:focus + .wallet-dropdown {
    display: block;
}

.wallet-combo {
    width: 100%;
    padding: res(8);
    border: res(1) solid #ccc;
    border-radius: res(4);
}


.clear-icon {
    position: absolute;
    right: res(10);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: res(16);
    color: #999;
    display: none;
}

input:focus + .clear-icon {
    display: block;
}

input.error {
    border-color: red;
}
.error-message {
    color: red;
    font-size: res(12);
    display: none;
    letter-spacing: -0.24%;
    font-family: $medium;
}
input.error {
    border-color: red;
    color: red;
}

.min.error {
    color: red;
}

.error-icon {
    position: absolute;
    right: res(15); /* Позиция зависит от вашего дизайна */
    top: 50%;
    transform: translateY(-50%);
    color: red;
    font-size: res(16);
    display: none; /* Скрыто по умолчанию */
}

input.error + .error-icon {
    display: block; /* Показывается при наличии ошибки */
}

input.error + .clear-icon {
    display: none; /* Скрыть крестик при наличии ошибки */
}


.buy__like{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: res(12);
    justify-content: center;

    .buy__like__item{
        width: res(155);
        height: res(100);
        display: flex;
        align-items: center;
        gap: res(10);
        cursor: pointer;
        border-radius: res(16);
        box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
        padding: res(10) res(4);

        .image{
            width: res(50);
            height: res(50);

            img{
                width: res(50);
            height: res(50);
            }
        }

        .info{
            display: flex;
            gap: res(10);
            flex-direction: column;
            align-items: center;
            width: res(80);

            p{
                font-size: res(14);
                font-family: $bold;
                color: $primary-color;
                text-align: center;
            }

            .btn{
                margin-top: 0;
                height: res(38)!important;
                box-shadow: none!important;
                font-size: res(12);
                padding-left: res(16);
                padding-right: res(16);

                img {
                    margin-bottom: res(2);
                    width: res(15);
                    height: res(15);
                    margin-left: res(3);
                }
            }

        }
    }
}

[data-modal-name="купить-лайки"], [data-modal-name="прокачать-канал"], [data-modal-name="обменник"]{
    .shop__content{
        padding: 0;
    }
}