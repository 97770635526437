@charset "UTF-8";
/* stylelint-disable */
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Montserrat-Bold";
  src: url("../fonts/../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("../fonts/../fonts/Montserrat-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Light";
  src: url("../fonts/../fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url("../fonts/../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../fonts/../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../fonts/../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url("../fonts/../fonts/Montserrat-Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  /* user-select: none!important; */
  -webkit-tap-highlight-color: transparent !important;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

html,
body {
  height: 100%;
  line-height: 1;
}

img {
  user-select: none;
}

/* [class*="__container"]{

} */
.wrapper {
  display: flex;
  flex-direction: column;
  height: var(--content-height);
  overflow: hidden;
  width: 100%;
  max-width: 108.9743589744vw;
  margin: 0 auto;
  position: relative;
  background-color: #fefefe;
}

main {
  flex: 1 1 auto;
  padding-bottom: 15.3846153846vw;
  overflow: hidden;
}

.page {
  display: none;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 33.0769230769vw - 15.3846153846vw);
  height: 100%;
  /* height: 100%;
  min-height: res(474); */
  padding-left: 3.0769230769vw;
  padding-right: 3.0769230769vw;
  padding-top: 5.1282051282vw;
  padding-bottom: 5.1282051282vw;
}

.page.active {
  display: flex;
}

.btn {
  cursor: pointer;
}

.primary--btn {
  padding: 4.1025641026vw 5.1282051282vw;
  background: #FF0000;
  box-shadow: 0 0 2.0512820513vw #FF0000;
  border-radius: 5.1282051282vw;
  color: white;
  font-size: 5.1282051282vw;
  font-family: "Montserrat-Bold";
  height: 14.358974359vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary--btn:disabled {
  background-color: #999999;
  box-shadow: none;
}

.telegram-finance {
  width: 100% !important;
  margin-top: 2.5641025641vw !important;
  font-size: 4.6153846154vw !important;
  font-family: "Montserrat-Medium" !important;
  box-shadow: none !important;
}
.telegram-finance svg {
  margin-right: 2.5641025641vw;
}

p.both {
  text-align: center;
  color: black;
  font-size: 4.1025641026vw;
  font-family: "Montserrat-Regular";
  letter-spacing: 0.37%;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FF0000;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
#preloader img {
  width: 100%;
  height: 100%;
}

.loading__border {
  width: 70%;
  height: 10px;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.35);
}

.loading-bar {
  width: 0;
  height: 100%;
  background-color: #FF0000;
  transition: width 0.2s ease;
}

/* ton */
.tc-modal, .go823957079 {
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  z-index: 9999999999 !important;
}

.tc-modal-content {
  max-width: 90%;
  max-height: 90%;
  padding: 5.1282051282vw;
  overflow-y: auto;
  border-radius: 2.0512820513vw;
}

.go1392445990 {
  padding: 0 !important;
}

.go1959085787 {
  padding: 2.5641025641vw !important;
}

.go3516602140 {
  padding: 2.5641025641vw !important;
}

.go823957079 {
  padding: 0 !important;
}

.go3872688706, .go1901766449 {
  width: 100% !important;
  border-radius: 0 !important;
}

.go2218940523 {
  height: 14.6153846154vw !important;
  padding: 4.1025641026vw !important;
}

.go3646674824, .go653478106 {
  margin-top: 0 !important;
  padding-top: 15.3846153846vw !important;
}

/* .go4160105817{
    padding-top: res(20)!important;
} */
.wallet__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 6.9230769231vw;
  gap: 3.0769230769vw;
}
.wallet__btn .btn {
  box-shadow: none !important;
  margin-top: 0 !important;
}
.wallet__btn .btn:first-child {
  width: 100%;
}
.wallet__btn .btn:last-child {
  width: 15.3846153846vw !important;
}

#language-switch {
  position: absolute;
  bottom: 20.5128205128vw;
  right: 3.3333333333vw;
  width: 12.8205128205vw;
  height: 12.8205128205vw;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;
  font-size: 3.5897435897vw;
  color: white;
  font-family: "Montserrat-SemiBold";
  text-transform: uppercase;
  box-shadow: 0 0 2.0512820513vw #FF0000;
}

.header {
  width: 100%;
  padding: 3.0769230769vw;
  padding-bottom: 5.1282051282vw;
  border-bottom-left-radius: 8.9743589744vw;
  border-bottom-right-radius: 8.9743589744vw;
  box-shadow: 0vw 0.5128205128vw 1.0256410256vw 0vw rgba(0, 0, 0, 0.15);
}

.profile__header {
  display: flex;
  flex-direction: column;
  row-gap: 3.8461538462vw;
  position: relative;
}
.profile__header span {
  color: #414141;
  font-size: 3.0769230769vw;
  font-family: "Montserrat-Regular";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3.0769230769vw;
}

.profile__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile__info .left {
  display: flex;
  align-items: center;
  gap: 3.3333333333vw;
}
.profile__info .left .ranking__btn {
  cursor: pointer;
}
.profile__info .left .ranking__btn img {
  width: 7.1794871795vw;
  height: 7.1794871795vw;
}
.profile__info .left .avatar {
  width: 11.2820512821vw;
  height: 11.2820512821vw;
  border-radius: 50%;
  background-color: black;
  border: 0.5128205128vw solid #414141;
  padding: 0.5128205128vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile__info .left .avatar img {
  width: 10.7692307692vw;
  height: 10.7692307692vw;
  border-radius: 100%;
  object-fit: 100%;
}
.profile__info .left .nickname {
  font-size: 3.5897435897vw;
  color: #414141;
  font-family: "Montserrat-Bold";
}
.profile__info .wallet__link {
  display: flex;
  align-items: center;
  gap: 2.5641025641vw;
  cursor: pointer;
  margin-right: 5.1282051282vw;
  position: relative;
}
.profile__info .wallet__link .icon {
  width: 5.1282051282vw;
  height: 4.358974359vw;
}
.profile__info .wallet__link p.wallet__link--text {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Bold";
  color: #414141;
  letter-spacing: -0.24%;
}
.profile__info .wallet__link .wallet__popup {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 0.2564102564vw solid #ccc;
  padding: 2.5641025641vw;
  border-radius: 1.0256410256vw;
  box-shadow: 0 1.0256410256vw 2.0512820513vw rgba(0, 0, 0, 0.1);
  flex-direction: column;
}
.profile__info .wallet__link .wallet__popup--button {
  padding: 1.2820512821vw 2.5641025641vw;
  cursor: pointer;
}

.profile__data {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile__data .profile__data--item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.3333333333vw;
  user-select: none;
  cursor: pointer;
  width: 29.4871794872vw;
  height: 9.7435897436vw;
  box-shadow: 0 0 1.0256410256vw rgba(0, 0, 0, 0.15);
  border-radius: 5.1282051282vw;
}
.profile__data .profile__data--item img {
  width: 6.1538461538vw;
  height: 6.1538461538vw;
  object-fit: 100%;
}
.profile__data .profile__data--item p {
  font-size: 4.1025641026vw;
  color: #414141;
  font-family: "Montserrat-Bold";
}

.referal__header {
  height: 24.8717948718vw;
  display: flex;
  align-items: center;
  gap: 3.0769230769vw;
}
.referal__header .current__stats {
  width: 50.2564102564vw;
}
.referal__header .general__stats {
  width: 40.5128205128vw;
}
.referal__header .general__stats, .referal__header .current__stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 3.0769230769vw;
  color: #414141;
}
.referal__header .general__stats p, .referal__header .current__stats p {
  font-size: 5.1282051282vw;
  font-family: "Montserrat-Bold";
}
.referal__header .general__stats span, .referal__header .current__stats span {
  font-size: 3.0769230769vw;
  font-family: "Montserrat-Regular";
  padding-top: 1.2820512821vw;
}
.referal__header .general__stats .btn, .referal__header .current__stats .btn {
  height: 8.2051282051vw;
  border-radius: 2.5641025641vw;
  box-shadow: none;
  font-size: 3.0769230769vw;
  width: 100%;
  color: #fff;
  margin-top: 3.0769230769vw;
}
.referal__header .general__stats .current__stats__info, .referal__header .current__stats .current__stats__info {
  display: flex;
  align-items: center;
  gap: 3.0769230769vw;
}
.referal__header .general__stats .current__stats__info img, .referal__header .current__stats .current__stats__info img {
  width: 11.7948717949vw;
  height: 11.7948717949vw;
}

.bottom__menu {
  width: 100%;
  padding-top: 2.0512820513vw;
  padding-bottom: 1.0256410256vw;
  padding-left: 3.0769230769vw;
  padding-right: 3.0769230769vw;
  border-top-left-radius: 8.9743589744vw;
  border-top-right-radius: 8.9743589744vw;
  box-shadow: 0 -0.5128205128vw 1.0256410256vw 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fefefe;
  z-index: 99999999;
}

.bottom__menu__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom__menu__item {
  width: 18.9743589744vw;
  height: 12.3076923077vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom__menu__item a svg {
  object-fit: 100%;
}
.bottom__menu__item a svg:nth-child(1) {
  width: 9.4871794872vw;
  height: 6.9230769231vw;
}
.bottom__menu__item a svg:nth-child(2) {
  width: 8.2051282051vw;
  height: 8.2051282051vw;
}
.bottom__menu__item a svg:nth-child(3) {
  width: 8.7179487179vw;
  height: 8.2051282051vw;
}
.bottom__menu__item a svg:nth-child(4) {
  width: 8.2051282051vw;
  height: 8.2051282051vw;
}
.bottom__menu__item a svg:nth-child(5) {
  width: 8.2051282051vw;
  height: 8.2051282051vw;
}
.bottom__menu__item:nth-child(1) a svg path:first-child {
  fill: #414141;
}
.bottom__menu__item.active:nth-child(1) a svg path:first-child, .bottom__menu__item.active:nth-child(4) a svg path:first-child {
  fill: red;
}
.bottom__menu__item.active:nth-child(2) a svg path, .bottom__menu__item.active:nth-child(3) a svg path, .bottom__menu__item.active:nth-child(5) a svg path {
  fill: red;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  padding: 0 3.0769230769vw;
}

.modal--active {
  display: flex;
}

.modal--expanded {
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
}

.modal.shop {
  padding: 0;
}
.modal.shop .modal__content {
  align-self: flex-end;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  min-height: 76.9230769231vw;
  padding-top: 7.4358974359vw;
}
.modal.shop .modal__content #connect-ton-btn {
  box-shadow: none !important;
}
.modal.shop .modal__content .wallet-dropdown {
  background-color: #fff;
  height: 10vw;
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Medium";
  color: #414141;
  letter-spacing: 0.0948717949vw;
  width: 100%;
  outline: none;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  border: none;
  border-radius: 3.0769230769vw;
  padding-left: 3.0769230769vw;
  padding-right: 3.0769230769vw;
}

.modal__head {
  color: black;
  font-size: 5.1282051282vw;
  letter-spacing: 0.0948717949vw;
  font-family: "Montserrat-Bold";
}

.modal__span {
  color: black;
  font-size: 3.5897435897vw;
  letter-spacing: -0.24%;
  font-family: "Montserrat-Medium";
  margin-top: 2.0512820513vw;
}

.shop__content {
  width: 100%;
  padding: 0 4.6153846154vw;
  margin-top: 6.4102564103vw;
}

.channel__boost {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 102.5641025641vw;
}
.channel__boost .channel__boost__item {
  height: 16.4102564103vw;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  border-radius: 3.0769230769vw;
  padding: 0 4.1025641026vw;
  margin-top: 5.1282051282vw;
}
.channel__boost .channel__boost__item .left {
  display: flex;
  align-items: center;
  /* gap: res(12); */
}
.channel__boost .channel__boost__item .left img {
  width: 9.7435897436vw;
  height: 9.7435897436vw;
}
.channel__boost .channel__boost__item .left p {
  font-size: 4.1025641026vw !important;
  font-family: "Montserrat-Bold";
  color: #414141;
  letter-spacing: -0.24%;
}
.channel__boost .channel__boost__item .btn {
  width: 26.9230769231vw;
  height: 9.7435897436vw;
  margin-top: 0;
  gap: 0.7692307692vw;
  box-shadow: 0 !important;
  border-radius: 3.0769230769vw;
  font-size: 4.1025641026vw !important;
  padding-left: 2.5641025641vw !important;
  padding-right: 2.5641025641vw !important;
}
.channel__boost .channel__boost__item .btn img {
  width: 5.1282051282vw;
  height: 5.1282051282vw;
}

.modal__content {
  background-color: white;
  padding: 3.3333333333vw;
  padding-top: 10.2564102564vw;
  border-radius: 8.2051282051vw;
  position: relative;
  width: 100%;
  min-height: 25.641025641vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: max-height 0.3s ease;
}
.modal__content p.title,
.modal__content .modal__title {
  font-size: 6.1538461538vw;
  color: #414141;
  letter-spacing: 0.0948717949vw;
  font-family: "Montserrat-Bold";
  margin-top: 6.4102564103vw;
  padding-bottom: 3.0769230769vw;
}
.modal__content span.text,
.modal__content .modal__body {
  font-size: 4.6153846154vw;
  letter-spacing: -0.24%;
  color: #414141;
  font-family: "Montserrat-Regular";
  line-height: 7.6923076923vw;
  text-align: center;
}
.modal__content span.text.mtube,
.modal__content .modal__body.mtube {
  font-family: "Montserrat-Bold";
  color: red;
  font-size: 6.1538461538vw;
  text-align: center;
}
.modal__content .modal__image img {
  width: 32.0512820513vw;
  height: 32.0512820513vw;
}
.modal__content .btn {
  width: 100%;
  margin-top: 10.2564102564vw;
}

span.modal__close {
  position: absolute;
  top: 7.4358974359vw;
  right: 7.4358974359vw;
  cursor: pointer;
}
span.modal__close img {
  width: 5.3846153846vw;
  height: 6.6666666667vw;
}

.no-scroll {
  overflow: hidden;
}

.exchange__list {
  display: flex;
  flex-direction: column;
  gap: 3.0769230769vw;
}
.exchange__list .exchange__list__item {
  width: 100%;
  height: 21.5384615385vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  border-radius: 5.1282051282vw;
  padding: 0 3.0769230769vw;
}
.exchange__list .exchange__list__item .exchange {
  display: flex;
  align-items: center;
  gap: 2.0512820513vw;
}
.exchange__list .exchange__list__item p.title {
  color: black;
  font-size: 4.6153846154vw;
  letter-spacing: 0.0948717949vw;
  font-family: "Montserrat-Bold";
  margin-top: 4.0692307692vw !important;
}
.exchange__list .exchange__list__item img {
  width: 11.2820512821vw;
  height: 11.2820512821vw;
}
.exchange__list .exchange__list__item span img {
  width: 4.1025641026vw;
  height: 4.1025641026vw;
}
.exchange__list .exchange__list__item .from {
  width: 12.3076923077vw;
  height: 12.3076923077vw;
}

.back-button {
  position: absolute;
  top: 7.4358974359vw;
  left: 7.4358974359vw;
  background-color: transparent;
}
.back-button svg {
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  transform: rotate(180deg);
}
.back-button svg path {
  fill: #999999;
}

.form-title {
  display: none;
}

.exchange__form {
  height: 123.0769230769vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wallet-select {
  width: 100%;
  height: 13.3333333333vw;
  border-radius: 3.0769230769vw;
  padding: 0 3.8461538462vw;
  margin-bottom: 2.5641025641vw;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  font-size: 3.5897435897vw;
  color: #303030;
  font-family: "Montserrat-Medium";
  border: 0;
  outline: 0;
}
.wallet-select option *[selected] {
  font-family: "Montserrat-Bold" !important;
}

input[disabled] {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.input__wrapp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.input__wrapp .btn {
  margin-top: 0;
}
.input__wrapp .input__item {
  display: flex;
  flex-direction: column;
  padding: 3.0769230769vw;
  padding-bottom: 5.1282051282vw;
  gap: 3.0769230769vw;
}
.input__wrapp .input__item p.exchange-rate {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Regular";
  letter-spacing: 0.0948717949vw;
  color: #999999;
}
.input__wrapp .input__item:first-child {
  border-bottom: 0.2564102564vw solid rgba(153, 153, 153, 0.4196078431);
}
.input__wrapp .input__item img {
  width: 9.7435897436vw;
  height: 9.7435897436vw;
}
.input__wrapp .input__item .data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vw;
  gap: 3.0769230769vw;
}
.input__wrapp .input__item .data .currency {
  font-size: 7.1794871795vw;
  font-family: "Montserrat-Bold";
  color: #999999;
  letter-spacing: 0.0948717949vw;
}
.input__wrapp .input__item .data input {
  outline: none;
  border: 0;
  height: 100%;
  width: 100%;
  font-size: 8.2051282051vw;
  font-family: "Montserrat-Bold";
  color: #414141;
  letter-spacing: 0.0948717949vw;
}
.input__wrapp .input__item .head {
  display: flex;
  align-items: center;
  gap: 3.0769230769vw;
  font-size: 4.1025641026vw;
  font-family: "Montserrat-Regular";
  color: #999999;
  letter-spacing: -0.24%;
}
.input__wrapp .input__item .head img {
  width: 12.3076923077vw;
  height: 12.3076923077vw;
}

.withdrawal__form, .wallet__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 115.3846153846vw;
}
.withdrawal__form .head__wrapp, .wallet__form .head__wrapp {
  font-size: 3.5897435897vw !important;
  font-family: "Montserrat-Regular";
  color: #999999;
  letter-spacing: -0.24%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.withdrawal__form .head__wrapp .head, .wallet__form .head__wrapp .head {
  font-size: 3.5897435897vw !important;
}
.withdrawal__form .head__wrapp .head img, .wallet__form .head__wrapp .head img {
  width: 8.9743589744vw;
  height: 8.9743589744vw;
}
.withdrawal__form .input__item, .wallet__form .input__item {
  gap: 5.1282051282vw;
}
.withdrawal__form .input__item:first-child, .wallet__form .input__item:first-child {
  border-bottom: none;
}
.withdrawal__form .input__item p.balance, .wallet__form .input__item p.balance {
  font-size: 3.5897435897vw !important;
  font-family: "Montserrat-Regular";
  color: #999999;
  letter-spacing: -0.24%;
}
.withdrawal__form .adress .data, .wallet__form .adress .data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.withdrawal__form .adress .data .label, .wallet__form .adress .data .label {
  font-size: 4.1025641026vw !important;
  font-family: "Montserrat-Regular";
  color: #303030;
  letter-spacing: -0.24%;
}
.withdrawal__form .adress .data .label span, .wallet__form .adress .data .label span {
  font-family: "Montserrat-Bold";
}
.withdrawal__form .adress .data input, .wallet__form .adress .data input {
  font-size: 3.5897435897vw;
  letter-spacing: -0.24%;
  font-family: "Montserrat-Medium";
  color: black;
  height: 13.3333333333vw;
  border-radius: 3.0769230769vw;
  padding: 0 3.8461538462vw;
  padding-right: 7.6923076923vw;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
}
.withdrawal__form .adress .data input::placeholder, .wallet__form .adress .data input::placeholder {
  color: rgba(60, 60, 67, 0.3);
}

.wallet__form {
  height: auto;
  margin-top: 5.1282051282vw;
}
.wallet__form input {
  width: 100%;
  margin-top: 2.5641025641vw;
}

.exchange__form--shifted {
  /* transform: translateY(res(-263)); */
  position: fixed;
  top: 2%;
  /* left: 50%;
  transform: translateX(-50%); */
  transition: 0.3s ease;
}
.exchange__form--shifted .exchange__form {
  justify-content: flex-start;
}
.exchange__form--shifted .withdrawal__form {
  justify-content: flex-start;
}
.exchange__form--shifted .withdrawal__form .btn {
  margin-top: 16.6666666667vw;
}
.exchange__form--shifted .exchange__form--shifted {
  justify-content: flex-start;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.wallet-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 0.2564102564vw solid #ccc;
  border-radius: 1.0256410256vw;
  height: 10.2564102564vw !important;
  max-height: 25.641025641vw !important;
  overflow-y: auto;
  display: none;
  z-index: 100;
  margin-top: 2.0512820513vw;
}

.wallet-dropdown li {
  padding: 2.5641025641vw;
  cursor: pointer;
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Medium";
}

.wallet-dropdown li:hover {
  background-color: #f0f0f0;
}

.wallet-combo:focus + .wallet-dropdown {
  display: block;
}

.wallet-combo {
  width: 100%;
  padding: 2.0512820513vw;
  border: 0.2564102564vw solid #ccc;
  border-radius: 1.0256410256vw;
}

.clear-icon {
  position: absolute;
  right: 2.5641025641vw;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 4.1025641026vw;
  color: #999;
  display: none;
}

input:focus + .clear-icon {
  display: block;
}

input.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 3.0769230769vw;
  display: none;
  letter-spacing: -0.24%;
  font-family: "Montserrat-Medium";
}

input.error {
  border-color: red;
  color: red;
}

.min.error {
  color: red;
}

.error-icon {
  position: absolute;
  right: 3.8461538462vw; /* Позиция зависит от вашего дизайна */
  top: 50%;
  transform: translateY(-50%);
  color: red;
  font-size: 4.1025641026vw;
  display: none; /* Скрыто по умолчанию */
}

input.error + .error-icon {
  display: block; /* Показывается при наличии ошибки */
}

input.error + .clear-icon {
  display: none; /* Скрыть крестик при наличии ошибки */
}

.buy__like {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.0769230769vw;
  justify-content: center;
}
.buy__like .buy__like__item {
  width: 39.7435897436vw;
  height: 25.641025641vw;
  display: flex;
  align-items: center;
  gap: 2.5641025641vw;
  cursor: pointer;
  border-radius: 4.1025641026vw;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  padding: 2.5641025641vw 1.0256410256vw;
}
.buy__like .buy__like__item .image {
  width: 12.8205128205vw;
  height: 12.8205128205vw;
}
.buy__like .buy__like__item .image img {
  width: 12.8205128205vw;
  height: 12.8205128205vw;
}
.buy__like .buy__like__item .info {
  display: flex;
  gap: 2.5641025641vw;
  flex-direction: column;
  align-items: center;
  width: 20.5128205128vw;
}
.buy__like .buy__like__item .info p {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Bold";
  color: #414141;
  text-align: center;
}
.buy__like .buy__like__item .info .btn {
  margin-top: 0;
  height: 9.7435897436vw !important;
  box-shadow: none !important;
  font-size: 3.0769230769vw;
  padding-left: 4.1025641026vw;
  padding-right: 4.1025641026vw;
}
.buy__like .buy__like__item .info .btn img {
  margin-bottom: 0.5128205128vw;
  width: 3.8461538462vw;
  height: 3.8461538462vw;
  margin-left: 0.7692307692vw;
}

[data-modal-name=купить-лайки] .shop__content, [data-modal-name=прокачать-канал] .shop__content, [data-modal-name=обменник] .shop__content {
  padding: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(76.9230769231vw);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeDown {
  from {
    transform: translateY(0vw);
  }
  to {
    transform: translateY(76.9230769231vw);
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes opacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes opacityOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes zoomOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.5);
  }
}
@keyframes pageOut {
  from {
    /* position: absolute;
    top: res(25);
    left: res(25); */
    opacity: 1;
  }
  to {
    /* position: absolute;
    top: res(25);
    left: res(25); */
    opacity: 0;
  }
}
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes rotate90 {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes rotate180 {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes rotatePlay {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes zoomPlay {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.start {
  width: 100%;
  height: 100%;
  padding: 0 3.0769230769vw;
  position: absolute;
  z-index: 99999999;
  top: 0;
  left: 0;
  background-color: #fff;
}
.start .start__body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  gap: 1.0256410256vw;
}

.start__top {
  display: flex;
  justify-content: space-between;
  height: 9.2307692308vw;
  align-items: center;
}
.start__top .start__steps {
  width: 56.4102564103vw;
  display: flex;
  justify-content: space-between;
}
.start__top .start__steps .start__steps--item {
  width: 9.2307692308vw;
  height: 1.0256410256vw;
  background-color: #F0F0F0;
  border-radius: 3.0769230769vw;
}
.start__top .start__steps .start__steps--item.passed {
  background-color: #FF0000;
}
.start__top .start__skip {
  font-size: 3.5897435897vw;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 500;
  color: red;
}

.start__nav {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 3.8461538462vw;
}
.start__nav .start__nav--controll {
  width: 100%;
  height: 13.3333333333vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 4.1025641026vw !important;
  letter-spacing: -0.0615384615vw !important;
  font-weight: bold !important;
  font-family: "Montserrat Alternates", sans-serif !important;
}
.start__nav .start__nav--controll .controll {
  display: flex;
  align-items: center;
  gap: 1.0256410256vw;
}
.start__nav .start__nav--controll .controll img {
  width: 7.1794871795vw;
  height: 7.1794871795vw;
}
.start__nav .start__nav--controll .start__prev {
  color: #414141;
}
.start__nav .start__nav--controll .start__next {
  color: red;
}
.start__nav .start__nav--controll .btn {
  height: 100%;
  box-shadow: none;
  font-weight: bold;
}

.start__content {
  display: flex;
  flex-direction: column;
}
.start__content .start__title {
  text-align: center;
  padding-bottom: 3.8461538462vw;
  font-size: 6.1538461538vw;
  font-family: "Montserrat Alternates", sans-serif;
  letter-spacing: -0.0615384615vw;
  color: #414141;
  font-weight: bold;
  margin-top: 5.1282051282vw;
}
.start__content .arrow__down {
  margin: 0 auto;
}
.start__content .arrow__down img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.start__content .step__image {
  /* width: 100%;
  max-height: res(290);
  img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  } */
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5.1282051282vw;
  pointer-events: none;
  max-height: 58.2051282051vw;
  padding-bottom: 5.1282051282vw;
}
.start__content .step__image .shop__lists {
  transform: scale(0.8);
}
.start__content .step__image .tasks__list {
  transform: scale(0.8);
}
.start__content .step__list {
  padding: 3.0769230769vw;
  display: flex;
  flex-direction: column;
  gap: 2.0512820513vw;
  /* margin-top: 10%; */
}
.start__content .step__list .step__list--item {
  font-size: 3.8461538462vw;
  font-weight: 500;
  color: #414141;
  font-family: "Montserrat Alternates", sans-serif;
  position: relative;
  padding-left: 7.6923076923vw;
}
.start__content .step__list .step__list--item:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: #414141;
  width: 1.0256410256vw;
  height: 1.0256410256vw;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-left: 3.8461538462vw;
}
.start__content .step__list .step__list--item span.black {
  font-weight: bold;
}
.start__content .step__list .step__list--item span.red {
  color: red;
}

.page[page=main] {
  flex-direction: column;
}
.page[page=main] .score {
  display: flex;
  align-items: center;
}
.page[page=main] .score p {
  font-size: 8.2051282051vw;
  font-family: "Montserrat-Bold";
  letter-spacing: 0.0948717949vw;
  color: #414141;
}
.page[page=main] .score img {
  width: 9.2307692308vw;
  height: 9.2307692308vw;
  object-fit: cover;
  margin-left: 2.0512820513vw;
}

.play {
  width: 83.3333333333vw;
  height: 83.3333333333vw;
  /* border-radius: 100%;
  box-shadow: 0 0 res(40) rgba(255, 0, 0, 0.6), 0 res(4) res(20) rgba(0, 0, 0, 0.3), inset 0 res(-4) res(10) rgba(0, 0, 0, 0.6); */
  cursor: pointer;
  position: relative;
  /* margin-top: res(40); */
  /* &::after{
      content: '';
      position: absolute;
      width: res(201);
      height: res(201);
      border: res(2) solid #DB0404;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      border-radius: 100%;
  } */
}
.play img {
  width: 83.3333333333vw;
  height: 83.3333333333vw;
}

.shop__lists {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 3.0769230769vw;
  row-gap: 5.1282051282vw;
}
.shop__lists .shop__item__btn {
  cursor: pointer;
  width: 45.3846153846vw;
  height: 48.9743589744vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  border-radius: 3.0769230769vw;
  padding: 5.1282051282vw 3.5897435897vw;
}
.shop__lists .shop__item__btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.shop__lists .shop__item__btn img {
  /* width: res(135); */
  height: 34.6153846154vw;
  padding-bottom: 1.2820512821vw;
}
.shop__lists .shop__item__btn p.title {
  font-family: "Montserrat-Bold";
  color: black;
  font-size: 4.1025641026vw;
}

.hidden {
  display: none;
}

.history {
  font-size: 3.0769230769vw;
  font-family: "Montserrat-Regular";
  color: #069CE8;
  letter-spacing: -0.24%;
  outline: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.history__back {
  position: absolute;
  top: 7.4358974359vw;
  left: 7.4358974359vw;
  width: 7.6923076923vw;
  height: 7.6923076923vw;
  outline: 0;
  border: none;
  background-color: transparent;
}
.history__back svg {
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  transform: rotate(180deg);
}
.history__back svg path {
  fill: #999999;
}

.history__list {
  margin-top: 5.1282051282vw;
  margin-bottom: 5.1282051282vw;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-size: 3.5897435897vw;
  color: #414141;
  gap: 3.0769230769vw;
}

@media screen and (max-height: 645px) {
  .shop__lists {
    row-gap: 2.5641025641vw !important;
  }
  .shop__item__btn {
    width: 45.3846153846vw !important;
    height: 44.8717948718vw !important;
    padding: 3.8461538462vw 2.5641025641vw !important;
  }
  .shop__item__btn img {
    height: 30.2564102564vw !important;
  }
}
.page[page=referal] {
  flex-direction: column;
}
.page[page=referal] p.head {
  font-size: 5.1282051282vw;
  font-family: "Montserrat-Bold";
  color: #414141;
}

.referal__level__lists {
  display: flex;
  flex-direction: column;
  gap: 3.0769230769vw;
  margin-top: 3.0769230769vw;
  width: 100%;
}

.referal__level__item {
  width: 100%;
  height: 12.8205128205vw;
  display: flex;
  align-items: center;
  padding-left: 4.1025641026vw;
  padding-right: 4.1025641026vw;
  justify-content: space-between;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  border-radius: 3.0769230769vw;
}
.referal__level__item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.referal__level__item p {
  font-size: 4.1025641026vw;
  font-family: "Montserrat-Bold";
  color: #414141;
}
.referal__level__item .friends {
  display: flex;
  align-items: center;
  gap: 2.0512820513vw;
}

.referal__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 6.9230769231vw;
  gap: 3.0769230769vw;
}
.referal__btn .btn:first-child {
  width: 100%;
}

.modal__content .users__list {
  display: flex;
  flex-direction: column;
  gap: 2.0512820513vw;
  margin-top: 5.1282051282vw;
  width: 100%;
}
.modal__content .users__list .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 11.2820512821vw;
  width: 100%;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  border-radius: 5.1282051282vw;
  padding: 0 5.1282051282vw;
}
.modal__content .users__list .user .name {
  letter-spacing: -0.0615384615vw;
  font-size: 4.1025641026vw;
  font-family: "Montserrat-Bold";
  color: #414141;
}
.modal__content .users__list .user .date {
  letter-spacing: -0.0615384615vw;
  font-size: 4.1025641026vw;
  font-family: "Montserrat-Medium";
  color: #414141;
}

.pagination {
  margin-top: 5.1282051282vw;
  gap: 5.641025641vw;
  /* .prev{
      margin-top: ;
  } */
}
.pagination div {
  gap: 2.5641025641vw;
}
.pagination button {
  background-color: transparent;
}

.modal__content {
  display: flex;
  flex-direction: column;
}

.users__list {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 5.1282051282vw;
  display: flex;
  flex-direction: column;
  gap: 2.0512820513vw;
  padding: 0.5128205128vw;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 5.1282051282vw;
  gap: 5.641025641vw;
}

.pagination button {
  background-color: transparent;
}

@media screen and (max-height: 645px) {
  .referal__level__item {
    height: 11.0256410256vw !important;
  }
  .referal__level__item p {
    font-size: 3.5897435897vw !important;
  }
  .referal__btn {
    margin-top: 2.5641025641vw !important;
  }
}
.page[page=tasks] {
  padding-bottom: 0;
  flex-direction: column;
  justify-content: flex-end;
}

.tasks__header {
  color: black;
  text-align: center;
  width: 100%;
}
.tasks__header p {
  font-size: 5.1282051282vw;
  font-family: "Montserrat-Bold";
  padding-bottom: 3.0769230769vw;
}
.tasks__header span {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Regular";
}

.tasks__list {
  height: 97.4358974359vw;
  overflow: auto;
  width: 100%;
  gap: 5.1282051282vw;
  display: block;
  margin-top: 5.1282051282vw;
  padding: 0 1.2820512821vw;
}
.tasks__list .tasks__item {
  height: 17.6923076923vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 2.5641025641vw;
  padding-right: 2.5641025641vw;
  box-shadow: 0 0 0.5128205128vw rgba(0, 0, 0, 0.35);
  border-radius: 5.1282051282vw;
}
.tasks__list .tasks__item:first-child {
  margin-top: 1.2820512821vw;
}
.tasks__list .tasks__item:last-child {
  margin-bottom: 1.2820512821vw;
}
.tasks__list .tasks__item[status=new] .status img {
  width: 4.1025641026vw;
  height: 4.1025641026vw;
}
.tasks__list .tasks__item[status=checked] {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.tasks__list .tasks__item[status=checked] .status img {
  width: 6.1538461538vw;
  height: 6.1538461538vw;
}
.tasks__list .tasks__item:not(:first-child) {
  margin-top: 5.1282051282vw;
}
.tasks__list .tasks__item .tasks__item--info {
  display: flex;
  align-items: center;
  gap: 3.0769230769vw;
  font-size: 4.1025641026vw;
}
.tasks__list .tasks__item .tasks__item--info .icon img {
  width: 10.2564102564vw;
  height: 10.2564102564vw;
  border-radius: 50%;
}
.tasks__list .tasks__item .tasks__item--info .info {
  display: flex;
  flex-direction: column;
  gap: 1.0256410256vw;
  color: #414141;
}
.tasks__list .tasks__item .tasks__item--info .info p.count {
  font-family: "Montserrat-Bold";
  letter-spacing: 0.0948717949vw;
}
.tasks__list .tasks__item .tasks__item--info .info p.title {
  font-family: "Montserrat-SemiBold";
  letter-spacing: -0.1794871795vw;
}
.tasks__list .tasks__item .tasks__item--info .info .reward {
  display: flex;
  align-items: center;
  gap: 1.0256410256vw;
}
.tasks__list .tasks__item .tasks__item--info .info .reward img {
  width: 5.1282051282vw;
  height: 5.1282051282vw;
  object-fit: 100%;
}

@media screen and (max-height: 645px) {
  .tasks__list {
    height: 84.6153846154vw !important;
  }
}
.loader-icon {
  position: relative;
  width: 6.1538461538vw;
  height: 6.1538461538vw;
}

.outer-half-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 6.1538461538vw;
  height: 6.1538461538vw;
  border: 0.5128205128vw solid transparent;
  border-top-color: #414141;
  border-left-color: #414141;
  border-bottom-color: #414141;
  border-radius: 50%;
  animation: rotate-outer 1.8s linear infinite;
}

.inner-half-circle {
  position: absolute;
  top: 1.4102564103vw;
  left: 1.5384615385vw;
  width: 3.0769230769vw;
  height: 3.0769230769vw;
  border: 0.5128205128vw solid transparent;
  border-top-color: #414141;
  border-left-color: #414141;
  border-bottom-color: #414141;
  border-radius: 50%;
  animation: rotate-inner 1.8s linear infinite;
}

.get-reward-btn {
  padding: 2.3076923077vw !important;
  box-shadow: none !important;
  border-radius: 2.3076923077vw !important;
  font-size: 3.5897435897vw !important;
  font-family: "Montserrat-Medium" !important;
  height: 8.4615384615vw !important;
}

@keyframes rotate-outer {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-inner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.page[page=bonus] {
  flex-direction: column;
  gap: 6.4102564103vw;
}

.bonus__header {
  color: #414141;
  text-align: center;
}
.bonus__header p {
  font-size: 5.1282051282vw;
  font-family: "Montserrat-Bold";
  padding-bottom: 3.0769230769vw;
}
.bonus__header span {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Regular";
}

.bonus__image {
  width: 52.5641025641vw;
  height: 53.8461538462vw;
}
.bonus__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bonus__btn {
  width: 100%;
}
.bonus__btn .btn {
  width: 100%;
  margin-top: 3.0769230769vw;
}
.bonus__btn .bonus__time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #414141;
  width: 100%;
}
.bonus__btn .bonus__time p {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Regular";
}
.bonus__btn .bonus__time span {
  font-size: 5.1282051282vw;
  font-family: "Montserrat-Bold";
}

@media screen and (max-height: 645px) {
  .bonus__image {
    width: 34.6153846154vw !important;
    height: 31.2820512821vw !important;
  }
}
.ranking__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 6.4102564103vw 0;
}
.ranking__wrapper.active {
  display: flex;
}
.ranking__wrapper .btn__back {
  display: flex;
  align-items: center;
  gap: 1.0256410256vw;
  padding-bottom: 6.4102564103vw;
  margin-left: 3.0769230769vw;
}
.ranking__wrapper .btn__back img {
  transform: rotate(180deg);
}
.ranking__wrapper .btn__back p {
  color: #414141;
  font-family: "Montserrat-Medium";
  letter-spacing: -0.1794871795vw;
}
.ranking__wrapper .header {
  text-align: center;
}
.ranking__wrapper .header .title {
  letter-spacing: 0.0948717949vw;
  font-size: 5.1282051282vw;
  font-family: "Montserrat-Bold";
  color: black;
  padding-bottom: 3.0769230769vw;
}
.ranking__wrapper .header span {
  letter-spacing: -0.0615384615vw;
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Regular";
  color: black;
}
.ranking__wrapper .profile__info {
  margin-top: 8.2051282051vw;
  padding: 3.0769230769vw 5.1282051282vw;
  justify-content: space-between;
}
.ranking__wrapper .profile__info .profile__info--wrapper {
  display: flex;
  align-items: center;
  gap: 3.0769230769vw;
}
.ranking__wrapper .score {
  display: flex;
  align-items: center;
  gap: 1.0256410256vw;
}
.ranking__wrapper .score img {
  width: 7.1794871795vw;
  height: 7.1794871795vw;
}
.ranking__wrapper .score p {
  letter-spacing: -0.0615384615vw;
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Regular";
  color: #414141;
}

.ranking {
  padding: 0 3.0769230769vw;
  width: 100%;
  margin-top: 6.1538461538vw;
}
.ranking .ranking__head {
  padding-bottom: 3.0769230769vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ranking .ranking__head p {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Medium";
  letter-spacing: -0.0615384615vw;
  color: black;
  opacity: 0.4;
}

.ranking__list {
  display: block;
  overflow: auto;
  width: 100%;
  height: 102.5641025641vw;
  padding: 0 1.2820512821vw;
  gap: 3.0769230769vw;
  margin-bottom: 2.5641025641vw;
  padding-bottom: 35%;
}
.ranking__list .ranking__list__item {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
  /* justify-content: space-between; */
  height: 16.4102564103vw;
}
.ranking__list .ranking__list__item .top {
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.1025641026vw;
  font-family: "Montserrat-Bold";
  letter-spacing: -0.0615384615vw;
  color: #414141;
}
.ranking__list .ranking__list__item .top img {
  width: 6.6666666667vw;
  height: 6.6666666667vw;
}
.ranking__list .ranking__list__item .user__data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3.0769230769vw;
  flex-direction: row-reverse;
  width: fit-content;
}
.ranking__list .ranking__list__item .user__data p {
  font-size: 3.5897435897vw;
  font-family: "Montserrat-Bold";
  letter-spacing: -0.0615384615vw;
  color: #414141;
}
.ranking__list .ranking__list__item .user__data img {
  width: 8.2051282051vw;
  height: 8.2051282051vw;
}
.ranking__list .ranking__list__item .score {
  width: 100%;
  justify-content: flex-end;
}