.bottom__menu{
    width: 100%;
    padding-top: res(8);
    padding-bottom: res(4);
    padding-left: res(12);
    padding-right: res(12);
    border-top-left-radius: res(35);
    border-top-right-radius: res(35);
    box-shadow: 0 -0.5128205128vw 1.0256410256vw 0 rgba(0, 0, 0, 0.15);
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fefefe;
    z-index: 99999999;
}
.bottom__menu__list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottom__menu__item{
    width: res(74);
    height: res(48);
    display: flex;
    justify-content: center;
    align-items: center;

    a{
        svg{
            &:nth-child(1){width: res(37);height: res(27);}
            &:nth-child(2){width: res(32);height: res(32);}
            &:nth-child(3){width: res(34);height: res(32);}
            &:nth-child(4){width: res(32);height: res(32);}
            &:nth-child(5){width: res(32);height: res(32);}

            object-fit: 100%;
        }
    }

    &:nth-child(1){
        a{
            svg{
                path:first-child{
                    fill: $primary-color;
                }
            }
        }
    }
    
    &.active:nth-child(1), 
    &.active:nth-child(4){
        a{
            svg{
                path:first-child{
                    fill: red;
                }
            }
        }
    }

    &.active:nth-child(2), 
    &.active:nth-child(3), 
    &.active:nth-child(5){
        a{
            svg{
                path{
                    fill: red;
                }
            }
        }
    }
}