.shop__lists{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    column-gap: res(12);
    row-gap: res(20);

    .shop__item__btn{
        cursor: pointer;
        width: res(177);
        height: res(191);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
        border-radius: res(12);
        padding: res(20) res(14);

        &:hover{
            background-color: rgba(0, 0, 0, 0.05);
        }

        img{
            /* width: res(135); */
            height: res(135);
            padding-bottom: res(5);
        }

        p.title{
            font-family: $bold;
            color: black;
            font-size: res(16);
        }
    }
}

.hidden{
    display: none;
}

.history{
    font-size: res(12);
    font-family: $regular;
    color: #069CE8;
    letter-spacing: -0.24%;

    outline: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;

    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.history__back{
    position: absolute;
    top: res(29);
    left: res(29);
    width: res(30);
    height: res(30);
    outline: 0;
    border: none;
    background-color: transparent;

    svg{
        width: res(26);
        height: res(26);
        transform: rotate(180deg);

        path{
            fill: #999999;
        }
    }
}

.history__list{
    margin-top: res(20);
    margin-bottom: res(20);
    display: flex;
    flex-direction: column;
    font-family: $regular;
    font-size: res(14);
    color: #414141;
    gap: res(12);
}

@media screen and (max-height: 645px){
    .shop__lists{
        row-gap: res(10)!important;
    }
    .shop__item__btn{
        width: res(177)!important;
        height: res(175)!important;
        padding: res(15) res(10)!important;

        img{
            height: res(118)!important;
        }
    }
}