@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(res(300));
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeDown {
    from {
        transform: translateY(res(0));
    }
    to {
        transform: translateY(res(300));
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes opacityIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes opacityOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes zoomOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.5);
    }
}

@keyframes pageOut {
    from {
        /* position: absolute;
        top: res(25);
        left: res(25); */
        opacity: 1;
    }
    to {
        /* position: absolute;
        top: res(25);
        left: res(25); */
        opacity: 0;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes rotate90 {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes rotate180 {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-180deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes rotatePlay {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes zoomPlay {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}