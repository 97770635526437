.page[page="tasks"]{
    padding-bottom: 0;
    flex-direction: column;
    justify-content: flex-end;
}

.tasks__header{
    color: black;
    text-align: center;
    width: 100%;

    p{
        font-size: res(20);
        font-family: $bold;
        padding-bottom: res(12);
    }

    span{
        font-size: res(14);
        font-family: $regular;
    }
}

.tasks__list{
    height: res(380);
    overflow: auto;
    width: 100%;
    gap: res(20);
    display: block;
    margin-top: res(20);
    padding: 0 res(5);

    .tasks__item{
        height: res(69);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding-left: res(10);
        padding-right: res(10);
        box-shadow: 0 0 res(2) rgba(0, 0, 0, 0.35);
        border-radius: res(20);

        &:first-child{
            margin-top: res(5);
        }

        &:last-child{
            margin-bottom: res(5);
        }

        &[status="new"]{

            .status{
                img{
                    width: res(16);
                    height: res(16);
                }
            }
        }

        &[status="checked"]{
            opacity: 0.5;
            user-select: none;
            pointer-events: none;

            .status{
                img{
                    width: res(24);
                    height: res(24);
                }
            }
        }

        &:not(:first-child){
            margin-top: res(20);
        }

        .tasks__item--info{
            display: flex;
            align-items: center;
            gap: res(12);
            font-size: res(16);

            .icon img{
                width: res(40);
                height: res(40);
                border-radius: 50%;
            }

            .info{
                display: flex;
                flex-direction: column;
                gap: res(4);
                color: $primary-color;

                p.count{
                    font-family: $bold;
                    letter-spacing: res(0.37);
                }

                p.title{
                    font-family: $semibold;
                    letter-spacing: res(-0.7);
                }

                .reward{
                    display: flex;
                    align-items: center;
                    gap: res(4);

                    img{
                        width: res(20);
                        height: res(20);
                        object-fit: 100%;
                    }
                }
            }
        }


    }
}

@media screen and (max-height: 645px){
    .tasks__list{
        height: res(330)!important;
    }
}

// loader

.loader-icon {
    position: relative;
    width: res(24);
    height: res(24);
}

.outer-half-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: res(24);
    height: res(24);
    border: res(2) solid transparent;
    border-top-color: #414141;
    border-left-color: #414141;
    border-bottom-color: #414141;
    border-radius: 50%;
    animation: rotate-outer 1.8s linear infinite;
}

.inner-half-circle {
    position: absolute;
    top: res(5.5);
    left: res(6);
    width: res(12);
    height: res(12);
    border: res(2) solid transparent;
    border-top-color: #414141;
    border-left-color: #414141;
    border-bottom-color: #414141;
    border-radius: 50%;
    animation: rotate-inner 1.8s linear infinite;
}

.get-reward-btn{
    padding: res(9)!important;
    box-shadow: none!important;
    border-radius: res(9)!important;
    font-size: res(14)!important;
    font-family: $medium!important;
    height: res(33)!important;
}

@keyframes rotate-outer {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-inner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}