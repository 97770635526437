@include font-face("Montserrat-Bold", "../fonts/Montserrat-Bold", 700, normal);
@include font-face("Montserrat-ExtraBold", "../fonts/Montserrat-ExtraBold", 800, normal);
@include font-face("Montserrat-Light", "../fonts/Montserrat-Light", 300, normal);
@include font-face("Montserrat-Medium", "../fonts/Montserrat-Medium", 500, normal);
@include font-face("Montserrat-Regular", "../fonts/Montserrat-Regular", 400, normal);
@include font-face("Montserrat-SemiBold", "../fonts/Montserrat-SemiBold", 600, normal);
@include font-face("Montserrat-Thin", "../fonts/Montserrat-Thin", 100, normal);

$bold: "Montserrat-Bold";
$extra: "Montserrat-ExtraBold";
$light: "Montserrat-Light";
$medium: "Montserrat-Medium";
$regular: "Montserrat-Regular";
$semibold: "Montserrat-SemiBold";
$thin: "Montserrat-Thin";